import React, { useEffect } from 'react'
import Breadcrumbs from '../components/BreadCrumbs'
import { useLocation } from 'react-router-dom';

const Aboutus = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]);
  return (
    <>
    
<section class="inner-banner p-100">
    <div class="container">
        <div class="row top-row mb-0 align-items-end">
            <div class="col-md-12 position-relative">
                {/* <div class="breadcrumbs">
                    <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div> */}
                <Breadcrumbs />
                <h2 class="site-title text-start mb-0"><span>About Us</span></h2>
            </div>
        </div>
    </div>
</section>

<section class="about-section p-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center explore-notes mb-4">
                {/* <h2 class="site-title mb-4">A Family of <span>Learners</span></h2>
                <h3 class="fw-bold text-dark mb-3">"Knowledge is power, but the right knowledge can empower."</h3>  */}
                <h4 class="sub-title d-table mx-auto mb-2">A Family of <span>Learners</span></h4>
                <h2 class="site-title mb-4">Knowledge is power, but <br /><span>Right Knowledge Can Empower</span></h2>
                <p class="fs-5 w-75 mx-auto">With digital media overpowering our lives, students, teachers, parents, professionals our overloaded with information. The right source of information saves us a lot of time. Witknowlearn thus aims to reach out to every student, teacher, parent, professional in the field of education.</p>
            </div>
        </div>
        <div class="row gx-lg-5">
            <div class="col-md-4 mb-4 mb-md-0">
                <div class="inwrp card single-sheet text-center h-100">
                    <img src="/assets/images/teacher.svg" alt="Image" />
                    <h3 class="mb-4">Every teacher’s wish is to enlighten young minds.</h3>
                    <p class="mb-0 lh-base">Our services will cater to the teachers who experience work-life imbalance, pressure to complete their syllabus, be task-oriented and meet deadlines.</p>
                </div>
            </div>
            <div class="col-md-4 mb-4 mb-md-0">
                <div class="inwrp card single-sheet text-center h-100">
                    <img src="/assets/images/student.svg" alt="Image" />
                    <h3 class="mb-4">Every student’s wish is that they feel cared about and heard.</h3>
                    <p class="mb-0 lh-base">Students can ease their tension by online assistance as and when required be it from choosing a career, improving their concentration or handling exam stress.</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="inwrp card single-sheet text-center h-100">
                    <img src="/assets/images/parent.svg" alt="Image" />
                    <h3 class="mb-4">Every parent’s wish is to be the best parent in this world.</h3>
                    <p class="mb-0 lh-base">Parents can find answers to their questions on effective parenting, psychological and emotional wellness of their child along with assistance to the holistic development of their child from a very young age.</p>
                </div>
            </div>
        </div>
    </div>
</section>
</>
  )
}

export default Aboutus
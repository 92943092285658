import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { get_blog, get_blog_by_id } from '../api/ApiAuth';
import Breadcrumbs from '../components/BreadCrumbs';
import { blog_thumbnail } from '../api/Common';

const PrivacyPolicy = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]);
    return (
        <>
        <section class="inner-banner p-100">
          <div class="container">
              <div class="row top-row mb-0 align-items-end">
                  <div class="col-md-12 position-relative">
                          <Breadcrumbs />
                      <h2 class="site-title text-start">Witknowlearn Privacy Policy</h2>
                      {/* <p class="mb-0">Interactive, research-based Math and Science worksheets fostering<br /> independent learning with real-world applications and digital flexibility.</p> */}
                      {/* <p class="mb-0">{blog.resource_synopsis}</p> */}
                  </div>
              </div>
          </div>
        </section>
      
      <section class="sheet-cnt pt-5">
          <div class="container">
              <div class="row flex-column-reverse flex-md-row position-relative">
                  <div class="col-md-12 right">
                      <div class="card inwrp inwrp d-table mx-auto mb-4">
                          {/* <div class="card-body w-50 mx-auto">
                              <img src={blog_thumbnail()+blog.thumbnail} alt="Image" class="w-100 img-fluid" />
                          </div> */}
                      </div>
                      <div class="inwrp">
                          <div className="card no-select">
                              <div className="card-body shadow-none" contenteditable="false" onCopy={(e) => e.preventDefault()}>
                              <div class="card-body text-left shadow-none p-0">
<div class="body-copy"><b>Cookie Policy</b></div>
<div class="body-copy mb-2">Our website and all the sub-domains (“Site”) works best using cookies. By continuing to use the Site, you agree to the use of cookies, including the ones already present on the device(s) as per our previous cookie policy.</div>
<div class="body-copy mb-2">The site also collects information by using technologies and methodologies that enable tracking, such as "cookies." A cookie is a text-only string of information that a website transfers to your computer (or other internet enabled devices, such as a smartphone or tablet) to be sent back to the website later. Cookies aren't viruses so they can’t be used to access any personal information on your computer when you accept them. By using the Site, you are consenting to our use of cookies in accordance with this Cookie Policy. If you do not agree to our use of cookies in this way, you can change your browser settings.</div>
<div class="body-copy mb-2">These technologies allow us to recognize your computer so that we can pre-fill data forms for you or display advertising that may be of interest to you. Cookies can only be read by the domain that placed them on your computer.</div>
<div class="body-copy mb-2">You can change your browser settings to refuse to accept cookies. However, if you change your browser settings to refuse to accept cookies, we cannot tailor the Site to meet your needs to the same degree.</div>
<div class="body-copy mb-2">Cookies help establish a user session and allow our server to correctly provide Site users with the appropriate information, advertisements and services. We also use cookies to ensure that you obtain the information you request and to authenticate your authority to access the Site. We also use cookies to track the usage of our Site in order to improve the Site according to our customers' needs.</div>
<div class="body-copy mb-2">The Site may enter into agreements with third party companies to serve advertisements and information to Site users. The Site may use its cookies when working with these companies to provide them information regarding use of the Site or to serve you advertisements when you visit our Site.</div>
<div class="body-copy mb-2">In addition, some third-party advertising technology used by witknowlearn to deliver advertisements may employ a separate cookie. Web beacons on some pages of our Site, in conjunction with these advertising cookies, enable the third party advertising technology to recognize a cookie or cookies on your computer when you visit the Site, and to display advertisements that might be of interest to you when you visit the Site.</div>
<div class="body-copy mb-2">Once you have given us your consent to the use of cookies, we shall store a cookie on your computer or device to remember this for next time. This will expire periodically. If you wish to withdraw your consent at any time, you will need to delete your cookies using your internet browser settings, but it will make your use of the Site less convenient.</div>
<div class="body-copy mt-4"><b>Privacy</b></div>
<div class="body-copy mb-2">The Publisher is committed to maintaining the privacy of personal information that you provide to us when using the Website. This Privacy Policy describes how we treat your personal information.</div>
<div class="body-copy mt-4"><b>What type of information is collected from you?</b></div>
<div class="body-copy mb-2">When you register, and at other times, we may collect personally identifiable information from you that may include, but not limited to, your name, email address, and facts about the equipment you use to access the Website and similar information. We automatically collect IP addresses and Website usage information.</div>
<div class="body-copy mt-4"><b>What is the intended purpose of Collection, Processing and Usage of Data?</b></div>
<div class="body-copy mb-2">We collect, store and process data to help us evaluate how our visitors and subscribers use and navigate our Website on an aggregate basis, including the number and frequency of visitors and subscribers to the Website and the length of their visits. We use this information to enhance existing features or develop new features. We will not share your personally identifiable information with any third party, unless you "opt in” to have your information shared with a company that is not affiliated with us. We may, however, distribute information in an aggregated form to third parties.</div>
<div class="body-copy mb-2">We may make content or services from other Websites including our affiliated or co-branded Websites available to you from links located on the Website. These other websites are not subject to this Privacy Policy.</div>
<div class="body-copy mb-2">We recommend that you review the privacy policy at each such website to determine how that site protects your privacy. We may occasionally conduct online surveys. These are voluntary and you may decline to participate. We ask you to do your bit by using a strong password to access the site. Please do not share your password with anyone. Do not use a common password for all your online activities and reset your password every so often.</div>
<div class="body-copy mb-2">We may change our Privacy Policy as and when deemed necessary, so please do check back from time to time.</div>
</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      </>
    );
};

export default PrivacyPolicy;
